import { createRouter, createWebHistory } from 'vue-router'
import Home from '@/views/Home'
import contact from '@/views/contact'
import Projects from '@/views/Projects'

const router = createRouter({
    // history: createWebHistory(),
    history: createWebHistory(process.env.BASE_URL), 
    
    
    routes: [
        {
            name: "Home",
            path: '/',
            component: Home
        },
        {
            name: "contact",
            path: '/contact',
            component: contact
        },
        {
            name: "Projects",
            path: '/Projects',
            component: Projects
        },

        
        
        
    ],
    scrollBehavior(to, from, savedPosition) {
        // always scroll to top
        return { top: 0 }
    },

      
})


export default router;


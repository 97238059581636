<template>
<div class="contact">
    <div class="content-frame">
        <h2>Contact me !</h2>
        <p>Feel free to reach me for any further information.</p>
        <form class="Formulaire" ref="form" @submit.prevent="sendEmail">
          <label>Your name</label>
          <input class="user_name" type="text" name="user_name">
          <label>Your email</label>
          <input class="mail" type="email" name="user_email">
          <label>message</label>
          <textarea class="message" name="message"></textarea>
          <input class="submit" type="submit" value="Send">
          <div class="email_not_send"></div>
        </form>
        
        <p>mail: contact@deonstudio.fr</p>
    </div>
</div>
</template>

<script>
import emailjs from '@emailjs/browser';

export default {
  
  methods: {
      sendEmail() {
          let divName = document.querySelector('.user_name');
          let divMsg = document.querySelector('.message');
          let divMail = document.querySelector('.mail');
          
          

          if(divName.value != "" && divMsg.value != "" && divMail.value != ""){
            const div = document.querySelector('.email_not_send');
            div.innerHTML = "Envoyé avec succès, merci "+divName.value+" pour ton message!";
            div.style.color = '#fff';

            
            
            emailjs.sendForm('service_ghmd05f', 'template_7v3nfl4', this.$refs.form, {publicKey:'72lEXY0ZsZQV2Wf_1'})
              .then((result) => {
                  console.log('SUCCESS!', result.text);
                  
              }, (error) => {
                  console.log('FAILED...', error.text);
                  
              });
          divName.value = "";
          divMsg.value = "";
          divMail.value = "";
      }else{
          const div = document.querySelector('.email_not_send');
          div.innerHTML = "informations incompletes !";
          div.style.color = '#d30';
        }
  }
 
  }
}
</script>

<style scoped>
.content-frame p{
  font-family: Font1;
}
.email_not_send{
  font-size: 1.5vw;
  
  font-family: Font1;
  color: #fff;
}
textarea{
  resize: none;
  width: 60%;
  height: 20vw;
  border: none;
  background: rgba(255, 255, 255, 0.25);
  border-radius: 5vh;
  box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
  backdrop-filter: blur(20px);
  -webkit-backdrop-filter: blur(20px);
  border: .2vh solid rgba(255, 255, 255, 1);
  font-size: 1.5vw;
  padding: 1%;
  font-family: Font1;
  color: #fff;
}
.submit{
  margin: 5%;
  width: 60%;
  background: rgba(255, 255, 255, 0.25);
  border-radius: 5vh;
  box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
  backdrop-filter: blur(20px);
  -webkit-backdrop-filter: blur(20px);
  border: .2vh solid rgba(255, 255, 255, 1);
  font-size: 2vw;
  font-family: Font1;
  color: #fff;
}
.submit:hover{
  margin: 5%;
  width: 55%;
  background: rgba(255, 255, 255, 0.25);
  border-radius: 5vh;
  box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
  backdrop-filter: blur(20px);
  -webkit-backdrop-filter: blur(20px);
  border: .2vh solid rgba(255, 255, 255, 1);
  font-size: 1.7vw;
  font-family: Font1;
  color: #fff;
}

input{
  height: 5vw;
  
  width: 60%;
  text-decoration: none;
  border: none;
  background: rgba(255, 255, 255, 0.25);
  border-radius: 5vh;
  box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
  backdrop-filter: blur(20px);
  -webkit-backdrop-filter: blur(20px);
  border: .2vh solid rgba(255, 255, 255, 1);
  font-size: 1.5vw;
  padding: .5%;
  font-family: Font1;
  color: #fff;
}
label{
  font-size: 2vw;
  font-family: Font1;
  color: #fff;
  margin: 1%;
}
form{
  
  width: 100%;
  display: flex;
	flex-direction: column;
	flex-wrap: nowrap;
	justify-content: flex-start;
	align-items: center;
	align-content: center;
}
/*---------------------------- */
.contact{
    width: 100%;
    
    text-align: center;
    display: flex;
}
.content-frame{
    width: 100%;
    flex: 1;

    
    padding-bottom: 5%;
}
p{
  font-size: 1.5vw;
  font-family: Font1;
  padding: 5%;
  color: #ffffff;
}
.Formulaire a{
  font-size: 2vw;
  font-family: Font1;
  color: #fff;
  
}
</style>
<template>
  <body>
    <div class="App">
        <div class="Top-Bar">
          <li><a href="/" @click="$router.push('/')">about</a></li>
          
          <li><a href="/contact" @click="$router.push('/contact')">contact</a></li>

          <li><a href="/Projects" @click="$router.push('/Projects')">projects</a></li>

          <li><a href="https://github.com/JohannDeon">github</a></li>
          <div class="Close">
              
              <img onclick="location.href='https://www.linkedin.com/in/johann-deon-89aaa5260/'" width="48" height="48" src="https://img.icons8.com/badges/48/linkedin.png" alt="linkedin"/>
          </div>
        </div>
      <router-view/>
    </div>
      

    
  </body>
</template>

<script>

</script>

<style>

@font-face {
font-family: "Font1";
src: url("./Fonts/deonstudio.otf") format("opentype");
}
@font-face {
font-family: "Font2";
src: url("./Fonts/Zeyada-Regular.ttf") format("truetype");
}
img{
  cursor: pointer;
}
.Close{
  width: 100%;
  height: 100%;
  display: flex;
	flex-direction: row;
	flex-wrap: nowrap;
	justify-content: flex-end;
	align-items: center;
	align-content: center;
  padding-right: 2%;
  
}
li a{
  margin: 0;
  text-decoration: none;
  color: #fff;
  font-family: Font1;
  font-size: 1.5vw;
  padding-top: 5%;
  
  cursor: pointer;
}
li {
  display: inline-block;
  position: relative;
  padding-bottom: 1%;
  margin-right: 10px;
  padding-left: 1%;
  padding-top: 1%;
}
li:last-child {
  margin-right: 0;
}

li:after {
  content: '';
  display: block;
  margin: auto;
  height: .5vh;
  
  width: 0px;
  background: transparent;
  transition: width .5s ease, background-color .5s ease;
}
li:hover:after {
  width: 90%;
  background: rgb(255, 255, 255);
}

.Top-Bar{
  display: flex;
	flex-direction: row;
	flex-wrap: nowrap;
	justify-content: flex-start;
	align-items: center;
	align-content: stretch;
  text-align: center;
  padding: 1%;
  border-radius: .3em .3em 0 0;
  background-color: #ffffff56;
  border-bottom: .2em solid #ffffff33;
}
h2{
  margin: 0;
  
  color: #fff;
  font-family: Font1;
  font-size: 1.5vw;
  padding-top: 5%;
  
  cursor: pointer;
}

h1{
  color: #fff;
  font-family: Font2;
  font-size: 10vw;
  
}
.App{
  width: 80vw;
  height: auto;
  background: rgba(0, 0, 0, 0.267);
  /* box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1); */
  backdrop-filter: blur(5.8px);
  -webkit-backdrop-filter: blur(18.8px);
  /* border: .5em solid #000000; */
  margin-top: 10vh;
  border-radius: 1em;
  -webkit-box-shadow: 0 .4em 0 .5em #000000;
  -moz-box-shadow: 0 .4em 0 .5em #000000;
  box-shadow: 0 .4em 0 .5em #000000;
  
}
body{

  margin: 0;
  cursor: cell;
  background-repeat: no-repeat ;  
  background-attachment: fixed;
  background-size: cover;
  background-image: url( './assets/SVGs/bg.svg' );
  display: flex;
	flex-direction: row;
	flex-wrap: nowrap;
	justify-content: center;
	align-items: center;
	align-content: stretch;
  
}

</style>

<template>
    <div class="home">
        <!-- <h1>Johann DEON</h1> -->

        <div class="wrapper">
            <svg>
                <text x="50%" y="50%" dy="0em" text-anchor="middle" >
                    Johann DEON
                </text>
            </svg>
        </div>


        <div class="Presentation">
            <div class="box">
                <div class="ribbon"><span>me</span></div>
                <img src="@/assets/Images/Profile.png" class="ProfilePicture"/>
            </div>
            
            <span class="Infos">
            <h3 align="left">about:</h3>

                <p>> 🔭 I’m currently working on <a class="highlight" href="https://www.deonstudio.fr">my website</a></p>
                <p>> 🌱 Actually, I'm learning different things like <span class="highlight">programmation, electrononical conception...</span></p>
                <p>> 🧑‍💻 At the moment, I'm working for <a class="highlight" href="https://serma-ingenierie.com/">SERMA INGENIERIE</a></p>
                <p>> 🎵 I'm also a <span class="highlight">music producer</span></p>
                <p>> 📫 How to reach me <span class="highlight">Johann@deonstudio.fr</span></p>
            </span>
        </div>
    </div>
</template>

<script>

</script>

<style>
@import url("https://fonts.googleapis.com/css2?family=Zeyada&display=swap");

svg {
	font-family: Font2;
	width: 100%; height: 100%;
}
svg text {
    display: block;
	animation: stroke 8s infinite alternate;
	stroke-width: 2;
	stroke: #FFFFFF;
	font-size: 500%;
    
    
    text-align: center;    
}
@keyframes stroke {
	0%   {
		fill: rgba(0,0,0,0); stroke: rgba(0, 0, 0, 0.781);
		stroke-dashoffset: 25%; stroke-dasharray: 0 50%; stroke-width: 2;
	}
	70%  {fill: rgba(0,0,0,0); stroke: rgba(255, 255, 255, 0.781); }
	80%  {fill: rgba(0,0,0,0); stroke: rgb(255, 255, 255); stroke-width: 3; }
	100% {
		fill: rgb(255, 255, 255); 
        stroke: rgba(255,255,255,0);
		stroke-dashoffset: -25%; 
        stroke-dasharray: 50% 0; 
        stroke-width: 0;
	}
}

.wrapper {
    background-color: none;
    width: 100%;
    height: 20vh;
    padding: 1%;
    
}




.home h1{
    margin: 0;
    padding: 1%;
}
.Infos{
    display: inline-block;
    color: #fff;
}
.Infos h3{
    font-size: 2vw;
    font-family: Font1;
    color: #fff;
}
.Infos p{
    font-size: 1vw;
    font-family: Font1;
    color: #fff;
}
.highlight{
    font-size: 1vw;
    font-family: Font1;
    color: #000000;
}

.Presentation{
    display: flex;
	flex-direction: row;
	flex-wrap: nowrap;
	justify-content: space-evenly;
	align-items: center;
	align-content: center;
    width: 100%;
}
.home{
    display: flex;
	flex-direction: column;
	flex-wrap: nowrap;
	justify-content: space-evenly;
	align-items: center;
	align-content: center;
    width: 80vw;
    height: auto;
    padding-bottom: 2%;
    
}
.ProfilePicture{
    /* clip-path: polygon(16% 83%, 70% 97%, 96% 58%, 83% 30%, 91% 6%, 30% 10%, 6% 46%); */
    cursor: pointer;
    height: 30vh;
    border-radius: .5em;
}


.box {
  width: auto; height: 30vh;
  position: relative;
  border: 2px solid #ffffffa4;
  background: none;
  border-radius: .6em;
}
.ribbon {
  position: absolute;
  left: -5px; top: -5px;
  z-index: 1;
  overflow: hidden;
  width: 20vh; height: 20vw;
  text-align: right;
}
.ribbon span {
  font-family: font1;
  font-size: 10px;
  font-weight: bold;
  color: #FFF;
  text-transform: uppercase;
  text-align: center;
  line-height: 20px;
  transform: rotate(-45deg);
  -webkit-transform: rotate(-45deg);
  width: 100px;
  display: block;
  background: #79A70A;
  background: black;
  box-shadow: 0 3px 10px -5px rgba(0, 0, 0, 1);
  position: absolute;
  top: 19px; left: -21px;
}
.ribbon span::before {
  content: "";
  position: absolute; left: 0px; top: 100%;
  z-index: -1;
  border-left: 3px solid #444444;
  border-right: 3px solid transparent;
  border-bottom: 3px solid transparent;
  border-top: 3px solid #5f5f5f;
}
.ribbon span::after {
  content: "";
  position: absolute; right: 0px; top: 100%;
  z-index: -1;
  border-left: 3px solid transparent;
  border-right: 3px solid #444444;
  border-bottom: 3px solid transparent;
  border-top: 3px solid #5f5f5f;
}

</style>
